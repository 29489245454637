<template>
    <div class="ele-page">
        <page-title :title="'配件管理'">维护相关的配件信息</page-title>
        <div class="page-content">
            <div class="sel-area">
                <el-input size="small" placeholder="请输入配件名称检索" v-model="selCon.eleName" clearable
                    style="width:280px;margin-right:10px;"></el-input>
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSel">查询</el-button>
            </div>
            <el-button size="small" type="success" style="margin-bottom:10px;" @click="addInfoDialog = true">新增配件
            </el-button>
            <el-table :data="tableData" size="mini" border style="width: 100%">
                <el-table-column type="index" label="序号" width="50" align="center">
                </el-table-column>
                <el-table-column prop="partName" label="配件名称" width="280" align="center">
                </el-table-column>
                <el-table-column prop="partModel" label="配件型号" width="240" align="center">
                </el-table-column>
                <el-table-column prop="price" label="配件价格" width="240" align="center">
                </el-table-column>
                <el-table-column prop="repairCost" label="配件维修费" width="240" align="center">
                </el-table-column>
                <el-table-column prop="remark" label="备注" align="center">
                </el-table-column>
                <el-table-column prop="createTime" label="创建日期" width="160" align="center">
                </el-table-column>
                <el-table-column label="操作" header-align="center" min-width="120" width="160" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="warning" @click="editInfo(scope.row)">修改</el-button>
                        <el-button size="mini" type="danger" @click="delInfo(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background style="margin-top:20px;text-align:right;" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" :current-page.sync="page.current" :page-sizes="[10, 50, 100, 200]"
                :page-size="page.pageSize" layout="total,sizes, prev, pager, next" :total="page.total">
            </el-pagination>
        </div>
        <el-dialog title="新增配件" :visible.sync="addInfoDialog" custom-class="my-dialog-style" @close="resetForm">
            <el-form ref="infoForm" :model="infoForm" size="small" label-width="100px" label-position="right">
                <el-row :gutter="10">
                    <el-col :span="12">
                        <el-form-item label="配件名称：" prop="partName">
                            <el-input v-model="infoForm.partName" placeholder="请输入配件名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="配件型号：" prop="partModel">
                            <el-input v-model="infoForm.partModel" placeholder="请输入配件型号"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="12">
                        <el-form-item label="配件价格：" prop="price">
                            <el-input-number v-model="infoForm.price" placeholder="请输入配件价格" :precision="2" :step="10" :min="0" style="width:100%;"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="维修费：" prop="repairCost">
                            <el-input-number v-model="infoForm.repairCost" placeholder="请输入配件维修费" :precision="2" :step="10" :min="0" style="width:100%;"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-form-item label="说明：" prop="remark">
                            <el-input v-model="infoForm.remark" placeholder="分类说明"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addInfoDialog = false" size="small">取 消</el-button>
                <el-button type="primary" @click="infoSaveOrEdit" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "Parts",
    data() {
        return {
            tableData: [],
            page: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
            addInfoDialog: false,
            infoForm: {},
            selCon: {
                partName: ""
            }
        }
    },
    created() {
        this.initPage();
    },
    methods: {
        /**电器检索 */
        handleSel() {
            const that = this;
            if (that.selCon.partName) {
                that.page.partName = that.selCon.partName;
            } else {
                that.page.partName = null;
            }
            that.initPage();
        },
        /**初始化数据列表 */
        initPage() {
            const that = this;
            let _params = that.page;
            that.request.post("parts/list", _params).then(res => {
                if (res.code === 200) {
                    that.tableData = res.data.records;
                    that.page.total = res.data.total;
                }
            });
        },
        /**删除电器信息 */
        delInfo(id) {
            const that = this;
            that.$confirm('此操作将永久删除该配件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                that.request.get("parts/delete", { id: id }).then(res => {
                    if (res.code === 200) {
                        that.initPage();
                    }
                });
            }).catch({});
        },
        /**修改电器表格数据 */
        editInfo(row) {
            this.infoForm = JSON.parse(JSON.stringify(row));
            this.addInfoDialog = true;
        },
        /**电器新增表单保存 */
        infoSaveOrEdit() {
            const that = this;
            that.request.post("parts/saveOrEdit", that.infoForm).then(res => {
                if (res.code === 200) {
                    that.addInfoDialog = false;
                    that.initPage();
                    that.$refs['infoForm'].resetFields();
                }
            });
        },
        /**重置表单 */
        resetForm() {
            if (this.$refs['classForm']) {
                this.$refs['classForm'].resetFields();
                this.classForm.id = null;
            } else if (this.$refs['infoForm']) {
                this.$refs['infoForm'].resetFields();
                this.infoForm.id = null;
            }
        },
        /**页面条数变化事件 */
        handleSizeChange(e) {
            this.page.pageSize = e;
            this.page.current = 1;
            this.initPage();
        },
        /**当前页变化 */
        handleCurrentChange() {
            this.initPage();
        }
    }
}
</script>
<style lang="less" scoped>
.ele-page {
    .page-content {
        width: 100%;

        .sel-area {
                margin: 10px 0px;
            }
    }

    /deep/ .el-tree-node__content {
        height: 36px;
    }

    .el-tag+.el-tag {
        margin-left: 10px;
    }

    .button-new-tag {
        margin-left: 10px;
    }

    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }
}
</style>